import { ApolloProvider } from '@apollo/client'
import FinancesIcon from '@atlaskit/icon/glyph/creditcard'
import AppsIcon from '@atlaskit/icon/glyph/jira/labs'
import AdminIcon from '@atlaskit/icon/glyph/office-building'
import UsersIcon from '@atlaskit/icon/glyph/person'
import AutopilotIcon from '@atlaskit/icon/glyph/premium'
import OpsIcon from '@atlaskit/icon/glyph/table'
import { colors } from '@atlaskit/theme'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'

import NavLayout, { Space } from './components/NavLayout'
import { client } from './graphql'
import Teams from './pages/Teams'
import Users from './pages/Users'
import WhosWho from './pages/WhosWho'
import AdminRoles from './pages/admin/AdminRoles'
import DebuggingTools from './pages/admin/DebuggingTools'
import AdhocTodos from './pages/apps/AdhocTodos'
import BugReports from './pages/apps/BugReports'
import Copilot from './pages/apps/Copilot'
import DelegationCoach from './pages/apps/DelegationCoach'
import Dossier from './pages/apps/Dossier'
import Features from './pages/apps/Features'
import MetricEvents from './pages/apps/MetricEvents'
import TopicTemplates from './pages/apps/TopicTemplates'
import Automations from './pages/autopilot/Automations'
import AutomationJobs from './pages/autopilot/Jobs'
import MessageTemplates from './pages/autopilot/Templates'
import AutopilotTesting from './pages/autopilot/Testing'
import Contractors from './pages/finances/Contractors'
import Invoicing from './pages/finances/Invoicing'
import Pricings from './pages/finances/Pricings'
import TimeEntries from './pages/finances/TimeEntries'
import Matchings from './pages/operations/Matchings'
import Orientation from './pages/operations/Orientation'
import Skillset from './pages/operations/Skillset'
import Transitions from './pages/operations/Transitions'

const GlobalStyle = createGlobalStyle`
  body {
    color: ${colors.text};
  }
`

const spaces: Space[] = [
  {
    title: 'Users',
    Icon: UsersIcon,
    pages: [Users, Teams, WhosWho, Orientation],
  },
  {
    title: 'Operations',
    Icon: OpsIcon,
    pages: [Matchings, Transitions, Skillset],
  },
  {
    title: 'Finances',
    Icon: FinancesIcon,
    pages: [Contractors, Invoicing, Pricings, TimeEntries],
  },
  {
    title: 'Autopilot',
    Icon: AutopilotIcon,
    pages: [MessageTemplates, Automations, AutomationJobs, AutopilotTesting],
  },
  {
    title: 'Apps',
    Icon: AppsIcon,
    pages: [
      TopicTemplates,
      Features,
      Dossier,
      MetricEvents,
      BugReports,
      Copilot,
      DelegationCoach,
      AdhocTodos,
    ],
  },
  {
    title: 'Admin',
    Icon: AdminIcon,
    pages: [AdminRoles, DebuggingTools],
  },
]

const App: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <GlobalStyle />
      <BrowserRouter>
        <NavLayout spaces={spaces} />
      </BrowserRouter>
    </ApolloProvider>
  )
}

export default App
