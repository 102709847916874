import { PropsWithChildren } from 'react'

import {
  onlyIfAdminable,
  useGetAdminableConfigQuery,
} from '../../../../graphql'

const DEBUG = false

const OnlyIfAdminRoles = ({
  userId,
  roles,
  children,
}: PropsWithChildren<{
  userId: string
  roles: string[]
}>) => {
  const { data } = useGetAdminableConfigQuery({
    variables: {
      userId,
    },
  })

  const adminable = onlyIfAdminable(data?.user)

  if (!DEBUG && !adminable?.adminRoles?.some(({ id }) => roles.includes(id))) {
    return null
  }

  return <>{children}</>
}

export default OnlyIfAdminRoles
