import ShortcutIcon from '@atlaskit/icon/glyph/shortcut'

import useGetClientDomain from '../../../../../hooks/useGetClientDomain'

type Props = {
  adhocId?: string
  isPool?: boolean
}

const ADHOC_SUB_PATH = 'adhoc/'
const POOL_SUB_PATH = 'pool/'

const TaskUrlLink = ({ adhocId, isPool = true }: Props) => {
  const clientDomains = useGetClientDomain()

  const subPath = isPool ? POOL_SUB_PATH : ADHOC_SUB_PATH

  if (!adhocId) {
    return null
  }

  return (
    <a href={`${clientDomains?.appUrl}${subPath}${adhocId}`} target={'_blank'}>
      <ShortcutIcon label={'Exec URL'} />
    </a>
  )
}

export default TaskUrlLink
