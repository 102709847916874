import { borderRadius, colors, typography } from '@atlaskit/theme'
import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import { Matching_ForMatchingListItemFragment } from '../../../../../graphql'
import ApplicationsBadge from '../../components/ApplicationsBadge'
import CatalogVisibilityBadge from '../../components/CatalogVisibilityBadge'
import CompletedBadge from '../../components/CompletedBadge'
import DueDateBadge from '../../components/DueDateBadge'
import HighTouchBadge from '../../components/HighTouchBadge'
import InstantInviteBadge from '../../components/InstantInviteBadge'
import ProposalsBadge from '../../components/ProposalsBadge'
import TeamInviteBadge from '../../components/TeamInviteBadge'
import TransitionBadge from '../../components/TransitionBadge'

const Outer = styled(NavLink)<{ isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  background: ${({ isSelected }) =>
    isSelected ? colors.backgroundActive : colors.N10};
  padding: 8px 12px;
  border-radius: ${borderRadius}px;

  &:hover {
    background: ${({ isSelected }) =>
      isSelected ? colors.backgroundActive : colors.backgroundHover};
  }

  & + & {
    margin-top: 4px;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  min-height: 20px;
  justify-content: space-between;
`

const Name = styled.div`
  ${typography.h200};
  margin: 0 0 4px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Badges = styled.div`
  display: flex;
`

type ItemProps = {
  matching: Matching_ForMatchingListItemFragment
}

const Item = ({ matching, ...props }: ItemProps) => {
  const currentRouteMatch = useRouteMatch()
  const href = `${currentRouteMatch.path}/${matching.id}`
  const match = useRouteMatch(href)
  const matchingProfile = matching.profiles[0]

  return (
    <Outer to={href} isSelected={!!match} {...props}>
      <Row>
        <Name>{matchingProfile?.name || '*Unknown*'}</Name>
        <CompletedBadge matching={matching} noText />
      </Row>

      <Row>
        <Badges>
          <TransitionBadge matching={matching} />
          <HighTouchBadge matching={matching} noText />
          <CatalogVisibilityBadge matching={matching} noText onlyIfHidden />
          <TeamInviteBadge matching={matching} noText />
          <InstantInviteBadge matching={matching} noText />
        </Badges>
        <Badges>
          {matchingProfile?.internalMatchingDate && (
            <DueDateBadge date={matchingProfile.internalMatchingDate} />
          )}
          <ApplicationsBadge matching={matching} />
          <ProposalsBadge matching={matching} />
        </Badges>
      </Row>
    </Outer>
  )
}

export default Item
