import Button from '@atlaskit/button'
import React, { useCallback } from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { ErrorEmptyState } from '../../../components/EmptyState'
import { LoadingSpinner } from '../../../components/Spinner'
import Tabs from '../../../components/Tabs'
import {
  useGetUserQuery,
  UserCategory,
  FullUserFragment,
  isAdminable,
  useCancelScheduledChurnMutation,
} from '../../../graphql'
import AutomationJobsInline from '../../autopilot/Jobs/ListInline'
import { isPending } from '../../autopilot/Jobs/utils'
import { formatDateTime } from '../../autopilot/utils'

import Accounts from './Accounts'
import Admin from './Admin'
import AssistantInfo from './AssistantInfo'
import Billing from './Billing'
import Emails from './Emails'
import Header from './Header'
import Matching from './Matching'
import Reporting from './Reporting'
import Score from './Score'
import UserInfo from './UserInfo'
import { MainOuter as Outer, MainContent as Content } from './styled'

const MOCK_DATA = {
  user: {
    __typename: undefined,
    id: '',
    profile: {
      displayName: 'qwertyuiopqwertyuiop',
      email: 'dwight.withdouble@gmail.com',
    },
    accounts: [],
    workspaces: [],
    category: undefined,
    pendingChurnAutomationJob: null,
  },
}

const removeFalse = function <T>(x: T | false): x is T {
  return Boolean(x)
}

const TabContent = styled.div<{ row?: boolean }>`
  overflow: auto;
  padding: 0 0 24px;
  flex: 1;
  ${(props) =>
    props.row &&
    css`
      display: flex;
      flex-direction: row;
    `}
`

const ChurnJobOuter = styled.div`
  padding: 0 32px;
  margin-bottom: 32px;
`

const User = () => {
  const {
    url,
    params: { userId },
  } = useRouteMatch<{ userId: string }>()
  const {
    data,
    loading: getUserLoading,
    error,
  } = useGetUserQuery({
    variables: { id: userId },
    skip: !userId,
  })

  const [cancelScheduledChurn, { loading: cancelScheduledChurnLoading }] =
    useCancelScheduledChurnMutation({
      variables: {
        input: {
          userId,
        },
      },
    })

  const loading = getUserLoading || cancelScheduledChurnLoading

  const mock = !data?.user
  const user = data?.user || MOCK_DATA.user
  const recipients = user.profile.email || ''

  const onCancelScheduledChurn = useCallback(() => {
    const userName = user.profile.displayName
    const alert = window.confirm(
      `Are you sure you want to cancel ${
        userName || 'the user'
      }'s scheduled churn?`,
    )

    if (!alert) return

    cancelScheduledChurn()
  }, [cancelScheduledChurn, user.profile.displayName])

  const tabs = [
    // Accounts and workspaces
    {
      label: 'Accounts',
      content: (
        <TabContent>
          <Accounts
            accounts={user?.accounts || []}
            selectedAccountKeys={null}
            onToggleAccount={() => undefined}
          />
        </TabContent>
      ),
    },
    // User info
    {
      label: 'Infos',
      content: (
        <TabContent>
          {/* @ts-ignore */}
          <UserInfo user={user} />
          {user.category !== UserCategory.EXECUTIVE && (
            // @ts-ignore
            <AssistantInfo user={user} />
          )}
        </TabContent>
      ),
    },
    // Matching info
    (user.category === UserCategory.ASSISTANT ||
      user.category === UserCategory.SANDBOX) && {
      label: 'Matching',
      content: (
        <TabContent>
          <Matching user={user} />
        </TabContent>
      ),
    },
    // Reporting info
    user.category === UserCategory.ASSISTANT && {
      label: 'Reporting',
      content: (
        <TabContent>
          <Reporting userId={userId} />
        </TabContent>
      ),
    },
    // Score info
    user.category === UserCategory.ASSISTANT && {
      label: 'Score',
      content: (
        <TabContent>
          <Score userId={userId} startDate={user.startDate} />
        </TabContent>
      ),
    },
    // Emails if not assistant
    user.category !== UserCategory.ASSISTANT && {
      label: 'Emails',
      content: (
        <TabContent>
          <Emails recipients={[recipients]} />
        </TabContent>
      ),
    },
    // Stripe if exec
    (user.category === UserCategory.EXECUTIVE ||
      user.category === UserCategory.SANDBOX) && {
      label: 'Billing',
      content: (
        <TabContent>
          <Billing userId={user.id} />
        </TabContent>
      ),
    },
    // Admin
    isAdminable(user) && {
      label: 'Admin',
      content: (
        <TabContent>
          <Admin userId={user.id} />
        </TabContent>
      ),
    },
  ].filter(removeFalse)

  const rightTabs = [
    {
      label: '🤖',
      slug: 'autopilot',
      content: (
        <TabContent>
          <AutomationJobsInline
            filter={{
              userId: user.id,
            }}
          />
        </TabContent>
      ),
    },
  ]

  if (!userId) {
    return null
  }

  return (
    <Outer>
      <Header user={user as FullUserFragment} skeleton={mock} />

      {!!user?.pendingChurnAutomationJob &&
        isPending(user.pendingChurnAutomationJob) && (
          <ChurnJobOuter>
            <p>
              {'🤖 This user is scheduled for churn on '}
              <b>{formatDateTime(user.pendingChurnAutomationJob.delayUntil)}</b>
            </p>
            <Button appearance={'danger'} onClick={onCancelScheduledChurn}>
              {'Cancel Scheduled Churn'}
            </Button>
          </ChurnJobOuter>
        )}

      <Content>
        {error ? (
          <ErrorEmptyState error={error} />
        ) : (
          <Tabs leftTabs={tabs} rightTabs={rightTabs} basePath={url} />
        )}
        <LoadingSpinner show={loading} />
      </Content>
    </Outer>
  )
}

export default User
