import PersonIcon from '@atlaskit/icon/glyph/person'
import DeleteIcon from '@atlaskit/icon/glyph/trash'
import { colors } from '@atlaskit/theme'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import {
  MoreDropdownMenu,
  DropdownItem,
  DropdownItemGroup,
  DropdownItemCopyText,
} from '../../../../components/DropdownMenu'
import Guard from '../../../../components/Guard'
import {
  TimeTrackingEntryFragment,
  useDeleteTimeTrackingEntryMutation,
} from '../../../../graphql'
import useSwitch from '../../../../lib/useSwitch'

import UpdateExecutiveModal from './UpdateExecutiveModal'

const Critical = styled.span`
  color: ${colors.red};
`

type Props = {
  entry: TimeTrackingEntryFragment
}

const MoreButton = ({ entry }: Props) => {
  const [isEditModalOpen, openEditModal, closeEditModal] = useSwitch()

  const [deleteTimeEntry] = useDeleteTimeTrackingEntryMutation({
    update: (cache, { data }) => {
      const deletedEntry = data?.deleteTimeTrackingEntry.timeTrackingEntry
      if (!deletedEntry) return

      cache.evict({
        id: cache.identify(deletedEntry),
      })

      cache.gc()
    },
  })

  const onDelete = useMemo(() => {
    return () => {
      const alert = window.confirm(
        'Are you sure you want to delete this time entry?',
      )

      if (!alert) return

      deleteTimeEntry({
        variables: {
          input: {
            timeTrackingEntryId: entry.id,
          },
        },
      })
    }
  }, [deleteTimeEntry, entry.id])

  return (
    <>
      <MoreDropdownMenu>
        <DropdownItemGroup>
          <DropdownItemCopyText text={entry.id}>
            {'Copy entry ID'}
          </DropdownItemCopyText>
          {!!entry.lockedAt && (
            <Guard
              operationId={'Mutation.updateTimeTrackingEntry.locked'}
              policy={'overlay'}
            >
              <DropdownItem
                onClick={openEditModal}
                elemBefore={
                  <PersonIcon size={'small'} label={'Update Executive'} />
                }
              >
                {'Update Executive'}
              </DropdownItem>
            </Guard>
          )}
          {!entry.isToggl && (
            <DropdownItem
              onClick={onDelete}
              isDisabled={entry.isToggl}
              elemBefore={
                <DeleteIcon
                  size={'small'}
                  label={'Delete'}
                  primaryColor={colors.red()}
                />
              }
            >
              <Critical>{'Delete'}</Critical>
            </DropdownItem>
          )}
        </DropdownItemGroup>
      </MoreDropdownMenu>
      {isEditModalOpen && (
        <UpdateExecutiveModal onClose={closeEditModal} entry={entry} />
      )}
    </>
  )
}

export default MoreButton
