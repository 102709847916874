import { Label } from '@atlaskit/form'
import { useCallback, useState, useMemo } from 'react'

import Modal from '../../../../../components/Modal'
import Toggle from '../../../../../components/Toggle'
import UserCell from '../../../../../components/users/Cell'
import { SingleUserSelect } from '../../../../../components/users/Select'
import {
  TimeTrackingEntryFragment,
  useGetUserNavigationQuery,
  useUpdateTimeTrackingEntryMutation,
} from '../../../../../graphql'
import useSwitch from '../../../../../lib/useSwitch'
import { DoubleIcon } from '../styled'

import { Inner, Row, ToggleContainer } from './styled'

type Props = {
  onClose: () => void
  entry: TimeTrackingEntryFragment
}

const UpdateExecutiveModal = ({ onClose, entry }: Props) => {
  const assistantId = entry.user.id
  const currentExec = entry.workspace?.executives[0]
  const [isBillToDoubleState, , , toggleBillToDouble] = useSwitch()

  const { data: userData, loading: getUserDataLoading } =
    useGetUserNavigationQuery({
      variables: { id: assistantId },
      skip: !assistantId,
    })

  const executives = useMemo(
    () =>
      userData?.user.workspaces.filter(
        (workspace) => workspace.executives[0].id !== currentExec?.id,
      ) || [],
    [currentExec?.id, userData?.user.workspaces],
  )

  const [updatedWorkspaceId, setUpdatedWorkspaceId] = useState<string | null>(
    null,
  )

  const [updateTimeEntry, { loading: updateTimeTrackingLoading }] =
    useUpdateTimeTrackingEntryMutation()

  const onUserSelect = useCallback(
    (user) => {
      //find the workspace id of the selected user
      const newWorkspaceId = userData?.user.workspaces.find(
        (workspace) => workspace.executives[0].id === user.id,
      )?.id
      if (!newWorkspaceId) return
      setUpdatedWorkspaceId(newWorkspaceId)
    },
    [userData?.user.workspaces],
  )

  const onSave = useCallback(() => {
    updateTimeEntry({
      variables: {
        input: {
          workspaceId: isBillToDoubleState ? null : updatedWorkspaceId,
          timeTrackingEntryId: entry.id,
        },
      },
      onCompleted: onClose,
    })
  }, [
    entry.id,
    isBillToDoubleState,
    onClose,
    updateTimeEntry,
    updatedWorkspaceId,
  ])

  const onToggleBillToDouble = useCallback(() => {
    if (isBillToDoubleState) {
      setUpdatedWorkspaceId(null)
    }
    toggleBillToDouble()
  }, [toggleBillToDouble, isBillToDoubleState])

  const loading = getUserDataLoading || updateTimeTrackingLoading
  return (
    <Modal
      isOpen
      onClose={onClose}
      height={800}
      heading={'Update Executive on a Locked Entry'}
      actions={[
        {
          text: isBillToDoubleState ? 'Bill to Double' : 'Update executive',
          onClick: onSave,
          isDisabled: !updatedWorkspaceId && !isBillToDoubleState,
        },
        { text: 'Cancel', onClick: onClose },
      ]}
    >
      <Inner>
        <h2>{'Entry'}</h2>
        <Row>
          {currentExec ? (
            <UserCell user={currentExec} />
          ) : (
            <Row>
              <DoubleIcon /> {'Bill to Double'}
            </Row>
          )}
          {`Task: ${entry.title}`}
        </Row>
        <h2>{'Update Bill To'}</h2>
        {currentExec && (
          <ToggleContainer>
            <Label htmlFor={'BillToDouble'}>{'Bill To Double'}</Label>
            <Toggle
              id={'BillToDouble'}
              label={'Bill To Double'}
              isChecked={isBillToDoubleState}
              isDisabled={loading}
              onChange={onToggleBillToDouble}
            />
          </ToggleContainer>
        )}
        {!isBillToDoubleState && (
          <SingleUserSelect
            onChange={onUserSelect}
            isLoading={loading}
            isDisabled={loading || isBillToDoubleState}
            defaultOptions={executives}
            placeholder={
              isBillToDoubleState
                ? 'Untoggle Bill to Double to Select an Exec'
                : 'Select Executive'
            }
          />
        )}
      </Inner>
    </Modal>
  )
}

export default UpdateExecutiveModal
