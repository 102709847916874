import { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle'
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle'
import { useCallback } from 'react'
import { v4 as uuidv4 } from 'uuid'

import {
  MoreDropdownMenu,
  DropdownItemCopyText,
} from '../../../../../../components/DropdownMenu'
import Guard from '../../../../../../components/Guard'
import {
  useRemoveAdhocTodoAssistantMutation,
  useUpdateAdhocTodoPoolVisibilityMutation,
} from '../../../../../../graphql/generated/hooks'

import {
  BlueColor,
  GrayColor,
  RedColor,
  RemoveDoubleText,
  VisibilityText,
} from './styled'

type Props = {
  adhocId: string
  removeDoubleDisabled: boolean
  hasDouble: boolean
  hasSandboxDouble: boolean
  isHiddenFromPool: boolean
}

const Actions = ({
  adhocId,
  removeDoubleDisabled: removeDoubleDisabledProp,
  hasDouble,
  hasSandboxDouble,
  isHiddenFromPool,
}: Props) => {
  const hasVisibilityPermission = true
  const hasDoubleRemovalPermission = true

  const [removeAdhocTodoAssistant] = useRemoveAdhocTodoAssistantMutation({
    onError: (error) => {
      console.error(error)
    },
  })

  const onRemoveDoubleClick = useCallback(() => {
    if (!adhocId) {
      return
    }
    removeAdhocTodoAssistant({
      variables: {
        input: {
          mutationId: uuidv4(),
          adhocTodoId: adhocId,
        },
      },
    })
  }, [adhocId, removeAdhocTodoAssistant])

  const [updateAdhocTodoPoolVisibility] =
    useUpdateAdhocTodoPoolVisibilityMutation({
      onError: (error) => {
        console.error(error)
      },
    })

  const toggleHidden = useCallback(() => {
    if (hasDouble && !hasSandboxDouble && !isHiddenFromPool) {
      if (
        !confirm(
          'There is a Double currently assigned, are you sure you want to hide this from the pool? They will not be able to view this task anymore.',
        )
      )
        return
    }

    updateAdhocTodoPoolVisibility({
      variables: {
        input: {
          mutationId: uuidv4(),
          adhocTodoId: adhocId,
          isHidden: !isHiddenFromPool,
        },
      },
    })
  }, [
    adhocId,
    hasDouble,
    hasSandboxDouble,
    isHiddenFromPool,
    updateAdhocTodoPoolVisibility,
  ])

  const cannotRemoveDouble =
    !hasDoubleRemovalPermission || removeDoubleDisabledProp

  return (
    <MoreDropdownMenu>
      <DropdownItemGroup>
        <DropdownItemCopyText text={adhocId}>
          {'Copy AdhocTodo ID'}
        </DropdownItemCopyText>
        <Guard
          operationId={'Mutation.removeAdhocTodoAssistant'}
          policy={'overlay'}
        >
          {hasDouble && (
            <DropdownItem
              onClick={onRemoveDoubleClick}
              isDisabled={cannotRemoveDouble}
              elemBefore={
                <CrossCircleIcon
                  size={'small'}
                  label={''}
                  primaryColor={cannotRemoveDouble ? GrayColor : RedColor}
                />
              }
            >
              <RemoveDoubleText $isDisabled={cannotRemoveDouble}>
                {removeDoubleDisabledProp
                  ? 'Cannot remove Double (Task Done)'
                  : 'Remove Double'}
              </RemoveDoubleText>
            </DropdownItem>
          )}
        </Guard>
        <Guard
          operationId={'Mutation.updateAdhocTodoPoolVisibility'}
          policy={'overlay'}
        >
          {
            <DropdownItem
              onClick={toggleHidden}
              isDisabled={!hasVisibilityPermission}
              elemBefore={
                isHiddenFromPool ? (
                  <CheckCircleIcon
                    size={'small'}
                    label={''}
                    primaryColor={BlueColor}
                  />
                ) : (
                  <CrossCircleIcon
                    size={'small'}
                    label={''}
                    primaryColor={RedColor}
                  />
                )
              }
            >
              <VisibilityText
                $isHiddenFromPool={isHiddenFromPool}
                $isDisabled={false}
              >
                {isHiddenFromPool ? 'Show in pool' : 'Hide from pool'}
              </VisibilityText>
            </DropdownItem>
          }
        </Guard>
      </DropdownItemGroup>
    </MoreDropdownMenu>
  )
}

export default Actions
