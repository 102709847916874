import { borderRadius, colors } from '@atlaskit/theme'
import Tooltip from '@atlaskit/tooltip'
import sortBy from 'lodash/sortBy'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import {
  AdminRoleFragment,
  ListAdminRolesQuery,
  useListAdminRolesQuery,
} from '../graphql'

import { Checkbox, SearchTextField } from './form'

const Outer = styled.ul`
  margin: 0;
`

const Item = styled.li`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-bottom: 1px solid ${colors.N30};
  &:hover {
    background: ${colors.backgroundActive};
    border-radius: ${borderRadius}px;
  }
`

const Labels = styled.div`
  display: flex;
  flex-direction: column;
`

const Description = styled.span<{ $bold: boolean }>`
  font-weight: ${({ $bold }) => ($bold ? 500 : 400)};
`

const Id = styled.span`
  font-size: 0.7em;
  color: ${colors.subtleText};
`

const Badge = styled.div<{ $color: string }>`
  height: 1.7em;
  width: 1.7em;
  border-radius: 1em;
  background: ${({ $color }) => $color};
  color: white;
  font-weight: 600;
  font-size: 0.8em;
  margin-right: 0.9em;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Spacer = styled.div`
  flex: 1;
`

const isInheritedByRoles = (
  data: ListAdminRolesQuery,
  operationId: string,
  inheritedRoleIds: string[],
): AdminRoleFragment[] => {
  const inheritedByRoleIds: string[] = []

  for (const inheritedRoleId of inheritedRoleIds) {
    const role = data.list.items.find(({ id }) => inheritedRoleId === id)
    if (role) {
      if (
        role.operations.some(({ id }) => id === operationId) ||
        isInheritedByRoles(
          data,
          operationId,
          role.inheritedRoles.map(({ id }) => id),
        ).length
      ) {
        inheritedByRoleIds.push(inheritedRoleId)
      }
    }
  }

  return data.list.items.filter(({ id }) => inheritedByRoleIds.includes(id))
}

type Props = {
  operationIds: string[]
  inheritedRoleIds: string[]
  onChangeOperationIds?: (operationIds: string[]) => void
}

const GuardedOperationsSelect = ({
  operationIds,
  onChangeOperationIds,
  inheritedRoleIds,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState<string>()
  const { data } = useListAdminRolesQuery()

  const getInheritingRoles = useMemo(() => {
    if (!data) {
      return () => []
    }
    const cache: Record<string, AdminRoleFragment[]> = {}

    return (operationId: string) => {
      if (!cache[operationId]) {
        cache[operationId] = isInheritedByRoles(
          data,
          operationId,
          inheritedRoleIds,
        )
      }

      return cache[operationId]
    }
  }, [data, inheritedRoleIds])

  const isAllowed = useCallback(
    (operationId: string): boolean => {
      return (
        operationIds.includes(operationId) ||
        getInheritingRoles(operationId).length > 0
      )
    },
    [getInheritingRoles, operationIds],
  )

  // const roles = data?.list.items || []

  const guardedOperations = useMemo(() => {
    return sortBy(
      data?.guardedOperations || [],
      ({ description }) => (description ? `0-${description}` : '1'),
      'id',
    ).filter(
      ({ id, typeName, fieldName, description }) =>
        !searchTerm ||
        [id, fieldName, typeName, description]
          .join(' ')
          .toLowerCase()
          .includes(searchTerm.toLowerCase().trim()),
    )
  }, [data?.guardedOperations, searchTerm])

  return (
    <Outer>
      <SearchTextField
        value={searchTerm}
        onChangeValue={setSearchTerm}
        isCompact
        placeholder={'Filter'}
        waitMs={1}
      />
      {guardedOperations.map(({ id, typeName, description, fieldName }) => (
        <Item key={id}>
          <Badge
            $color={
              typeName === 'Mutation'
                ? colors.yellow()
                : typeName === 'Query'
                ? colors.purple()
                : 'white'
            }
          >
            {typeName === 'Mutation' ? 'M' : typeName === 'Query' ? 'Q' : ''}
          </Badge>
          <Labels>
            <Description $bold={isAllowed(id)}>
              {description || fieldName}
            </Description>
            <Id>{id}</Id>
          </Labels>
          <Spacer />
          <Tooltip
            position={'left'}
            content={
              !operationIds.includes(id) && getInheritingRoles(id).length > 0
                ? `Inherited from: ${getInheritingRoles(id)
                    .map(({ name }) => name)
                    .join(', ')}`
                : undefined
            }
          >
            <Checkbox
              isChecked={isAllowed(id)}
              isIndeterminate={
                !operationIds.includes(id) && getInheritingRoles(id).length > 0
              }
              onChangeValue={() => {
                onChangeOperationIds?.(
                  operationIds.includes(id)
                    ? operationIds.filter((opId) => opId !== id)
                    : [...new Set([...operationIds, id])],
                )
              }}
              isDisabled={!onChangeOperationIds}
            />
          </Tooltip>
        </Item>
      ))}
    </Outer>
  )
}

export default GuardedOperationsSelect
