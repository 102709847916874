import Avatar, { AvatarItem } from '@atlaskit/avatar'
import DraftIcon from '@atlaskit/icon/glyph/billing'
import EditIcon from '@atlaskit/icon/glyph/edit'
import RefreshIcon from '@atlaskit/icon/glyph/refresh'
import SendIcon from '@atlaskit/icon/glyph/send'
import VisitIcon from '@atlaskit/icon/glyph/shortcut'
import VoidIcon from '@atlaskit/icon/glyph/trash'
import Tooltip from '@atlaskit/tooltip'
import { DateTime } from 'luxon'
import React, { ComponentProps, useCallback } from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'

import {
  MoreDropdownMenu,
  DropdownItemGroup,
  DropdownItem,
} from '../../../../components/DropdownMenu'
import Spinner from '../../../../components/Spinner'
import { Checkbox } from '../../../../components/form'
import { UserAvatarsGroup } from '../../../../components/users/Avatar'
import UserCell from '../../../../components/users/Cell'
import {
  StripeCustomerPaymentStatus,
  StripeInvoiceStatus,
  useGetInvoicingReportUserStripeStatusLazyQuery,
} from '../../../../graphql'
import { formatAmount, formatHours } from '../../Contractors/utils'
import {
  getReportTotals,
  ReportSummary,
  InvoiceEdit,
  getUsageException,
  ReportsQuery,
  getReportInvoicingAgreements,
  Exception,
} from '../utils'

import getStripeCustomerInvoicesVariables from './getStripeCustomerInvoicesVariables'
import {
  InvoiceOuter,
  InvoiceHeader,
  InvoiceBody,
  InvoiceContent,
  PackageOuter,
  PackageHeader,
  PackageName,
  TogglUsage,
  Monospaced,
  TooltipInner,
  HoursCell,
  AmountCell,
  CheckboxCell,
  ItemRow,
  ItemLabelCell,
  MoreMenuCell,
  PaymentStatusCell,
  PaymentStatusBadge,
  ExceptionCell,
  CsmCell,
  ExceptionBadge,
  StartDateCell,
  InvoiceStatusCell,
  InvoiceStatusBadge,
  InvoiceLinesTable,
  InvoiceDescription,
  ItemBadge,
} from './styled'
import useReportInvoiceActions from './useReportInvoiceActions'

type Props = ComponentProps<typeof InvoiceOuter> & {
  query: ReportsQuery
  report: ReportSummary
  edit: InvoiceEdit | null
  onPatchEdit: (
    reportId: ReportSummary['id'],
    editPatch: Partial<InvoiceEdit>,
  ) => void
  onEditInvoice: (reportId: ReportSummary['id']) => void
}

const ReportRow = ({
  query,
  report,
  edit,
  onPatchEdit,
  onEditInvoice,
  ...outerProps
}: Props) => {
  const { url } = useRouteMatch()

  const billingVariables = getStripeCustomerInvoicesVariables(query.to)

  const [fetchBillingInfo, billingInfo] =
    useGetInvoicingReportUserStripeStatusLazyQuery({
      variables: {
        ...billingVariables,
        userId: report.invoicedUser.id,
      },
    })

  const [
    { payAndSendInvoice, createInvoice, voidInvoice },
    invoiceActionsResult,
  ] = useReportInvoiceActions(query, billingVariables)

  const stripeCustomer = report.invoicedUser.stripeCustomer
  const invoice = stripeCustomer?.invoices?.[0]

  const isLoading = billingInfo.loading || invoiceActionsResult.loading

  const toggleExcluded = useCallback(
    (included: boolean) => {
      onPatchEdit(report.id, { isExcluded: !included })
    },
    [onPatchEdit, report.id],
  )

  const editInvoice = useCallback(
    () => onEditInvoice(report.id),
    [onEditInvoice, report.id],
  )

  const totals = getReportTotals(report, edit)

  return (
    <InvoiceOuter {...outerProps} selected={!edit?.isExcluded}>
      <InvoiceHeader>
        <NavLink to={`/users/${report.invoicedUser.id}`} target={'_blank'}>
          <UserCell user={report.invoicedUser} />
        </NavLink>

        <CsmCell>
          {/* {!!report.invoicedUser.csm && (
            <Avatar
              src={report.invoicedUser.csm.profile.avatarUrl || undefined}
              size={'small'}
            />
          )} */}
        </CsmCell>
        <StartDateCell />
        <ExceptionCell>
          {!!getReportInvoicingAgreements(report).length && (
            <ExceptionBadge exception={Exception.AGREEMENT} />
          )}
        </ExceptionCell>

        <HoursCell />
        <AmountCell
          bold
          highlight={
            !!totals.invoicedAmount &&
            formatAmount(totals.invoicedAmount, 'USD') !==
              formatAmount(totals.calculatedAmount, 'USD')
          }
        >
          {formatAmount(totals.amount, totals.invoicedCurrency || 'USD')}
        </AmountCell>

        <PaymentStatusCell>
          <a
            href={
              stripeCustomer?.id
                ? `https://dashboard.stripe.com/customers/${stripeCustomer?.id}`
                : `/users/${report.invoicedUser.id}/billing`
            }
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            {isLoading ? (
              <Spinner />
            ) : !stripeCustomer?.paymentStatus ? (
              <PaymentStatusBadge
                status={StripeCustomerPaymentStatus.MISSING}
              />
            ) : (
              <PaymentStatusBadge status={stripeCustomer.paymentStatus} />
            )}
          </a>
        </PaymentStatusCell>

        <InvoiceStatusCell>
          {isLoading ? (
            <Spinner />
          ) : (
            !!invoice && (
              <a
                href={`https://dashboard.stripe.com/invoices/${invoice.id}`}
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                <Tooltip
                  content={
                    <>
                      <InvoiceDescription>
                        {invoice.description}
                      </InvoiceDescription>
                      <InvoiceLinesTable>
                        {invoice.lineItems.map(
                          ({ amount, description, currency }, index) => (
                            <tr key={index}>
                              <td>{description}</td>
                              <td align={'right'}>
                                <strong>
                                  {formatAmount(amount || 0, currency)}
                                </strong>
                              </td>
                            </tr>
                          ),
                        )}
                        <tr>
                          <td align={'right'}>
                            <strong>{'Total'}</strong>
                          </td>
                          <td align={'right'}>
                            <strong>
                              {formatAmount(invoice.amount, invoice.currency)}
                            </strong>
                          </td>
                        </tr>
                      </InvoiceLinesTable>
                      <table>
                        <tr>
                          <td>{'Status'}</td>
                          <td>
                            <strong>{invoice.status}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>{'Created'}</td>
                          <td>
                            {/* @ts-ignore */}
                            {DateTime.fromISO(invoice.createdAt).toLocaleString(
                              DateTime.DATETIME_MED,
                            )}
                          </td>
                        </tr>
                      </table>
                    </>
                  }
                  component={TooltipInner}
                >
                  <InvoiceStatusBadge status={invoice.status} />
                </Tooltip>
              </a>
            )
          )}
        </InvoiceStatusCell>

        <CheckboxCell>
          <Checkbox
            isChecked={!edit?.isExcluded}
            onChangeValue={toggleExcluded}
          />
        </CheckboxCell>

        <MoreMenuCell>
          <MoreDropdownMenu
            triggerButtonProps={{
              isDisabled: !!edit?.isExcluded,
            }}
          >
            <DropdownItemGroup>
              <DropdownItem
                onClick={editInvoice}
                isDisabled={!!invoice}
                elemBefore={<EditIcon label={''} size={'small'} />}
              >
                {'Edit additional items'}
              </DropdownItem>
              <DropdownItem
                elemBefore={<RefreshIcon label={''} size={'small'} />}
                onClick={() => (billingInfo.refetch || fetchBillingInfo)()}
              >
                {'Refresh Stripe data'}
              </DropdownItem>
            </DropdownItemGroup>
            <DropdownItemGroup title={'Invoice'}>
              <DropdownItem
                isDisabled={!stripeCustomer}
                elemBefore={<DraftIcon label={''} size={'small'} />}
                onClick={() => {
                  if (
                    !!invoice &&
                    !window.confirm(
                      'Looks like there is already an existing invoice.\nDo you really want to create a draft for another invoice?',
                    )
                  ) {
                    return
                  }

                  createInvoice(report, edit, true)
                }}
              >
                {'Create draft invoice'}
              </DropdownItem>
              <DropdownItem
                isDisabled={invoice?.status !== StripeInvoiceStatus.DRAFT}
                elemBefore={<SendIcon label={''} size={'small'} />}
                onClick={() => payAndSendInvoice(report, edit)}
              >
                {'Charge card/Send invoice'}
              </DropdownItem>
              <DropdownItem
                isDisabled={
                  !invoice ||
                  [
                    StripeInvoiceStatus.PAID,
                    StripeInvoiceStatus.VOID,
                    StripeInvoiceStatus.DELETED,
                  ].includes(invoice.status)
                }
                elemBefore={<VoidIcon label={''} size={'small'} />}
                onClick={() => voidInvoice(report)}
              >
                {invoice?.status === StripeInvoiceStatus.DRAFT
                  ? 'Delete draft invoice'
                  : 'Void invoice'}
              </DropdownItem>
            </DropdownItemGroup>
          </MoreDropdownMenu>
        </MoreMenuCell>
      </InvoiceHeader>

      <InvoiceBody>
        <InvoiceContent>
          {report.packages.map((p) => (
            <PackageOuter
              key={p.principalUser.id}
              faded={!!edit?.excludedPackages?.[p.id]}
            >
              <PackageHeader>
                <PackageName>
                  {p.pricing && (
                    <Tooltip
                      content={
                        <>
                          {p.userPricing?.notepad && (
                            <>
                              {p.userPricing.notepad}
                              <br />
                              <br />
                            </>
                          )}
                          {p.pricing.ref}
                          <br />
                          {p.pricing.priceDescription}
                          <br />
                          {p.pricing.hoursDescription}
                          <br />
                          <br />
                          <Monospaced>{p.pricingFormula}</Monospaced>
                        </>
                      }
                      component={TooltipInner}
                    >
                      <NavLink to={`${url}/pricings/${p.pricing.id}`}>
                        {p.pricing.ref}
                        {!!p.userPricing?.notepad && ` (hover for notes)`}
                      </NavLink>
                    </Tooltip>
                  )}
                </PackageName>
                <CsmCell />
                <StartDateCell bold={p.monthsSinceStartDate < 2}>
                  {!!p.principalUser.startDate && (
                    <Tooltip
                      content={p.principalUser.startDate}
                      component={TooltipInner}
                      position={'top'}
                    >
                      <span>
                        {DateTime.fromISO(
                          p.principalUser.startDate,
                        ).toLocaleString({ year: 'numeric', month: 'short' })}
                      </span>
                    </Tooltip>
                  )}
                </StartDateCell>
                <ExceptionCell />
                <HoursCell bold>{formatHours(p.hours)}</HoursCell>
                <AmountCell>
                  <Tooltip
                    content={
                      <>
                        {'Start Date: '}
                        {p.principalUser.startDate}
                        <br />
                        <br />
                        <Monospaced>{p.pricingFormula}</Monospaced>
                      </>
                    }
                    component={TooltipInner}
                  >
                    <span>{formatAmount(p.amount, 'USD')}</span>
                  </Tooltip>
                </AmountCell>
                <PaymentStatusCell />
                <InvoiceStatusCell />
                <CheckboxCell>
                  <Checkbox
                    isDisabled={!!edit?.isExcluded || !!invoice}
                    isChecked={!edit?.excludedPackages?.[p.id]}
                    onChangeValue={(checked) =>
                      onPatchEdit(report.id, {
                        excludedPackages: {
                          ...edit?.excludedPackages,
                          [p.id]: !checked,
                        },
                      })
                    }
                  />
                </CheckboxCell>
              </PackageHeader>

              {p.togglUsages.map((usage) => (
                <TogglUsage key={usage.id}>
                  <NavLink
                    to={`/users/${usage.executive.id}`}
                    target={'_blank'}
                  >
                    <AvatarItem
                      primaryText={usage.executive.profile.displayName}
                      secondaryText={usage.assistant?.profile.displayName}
                      avatar={
                        <UserAvatarsGroup
                          size={'small'}
                          // @ts-ignore
                          users={[usage.executive, usage.assistant].filter(
                            Boolean,
                          )}
                        />
                      }
                    />
                  </NavLink>
                  <CsmCell>
                    {usage.executive.csm && (
                      <Avatar
                        src={
                          usage.executive.csm.user.profile.avatarUrl ||
                          undefined
                        }
                        size={'small'}
                      />
                    )}
                  </CsmCell>
                  <StartDateCell />
                  <ExceptionCell>
                    <ExceptionBadge exception={getUsageException(usage)} />
                  </ExceptionCell>
                  <HoursCell faded={!!edit?.excludedUsages?.[usage.id]}>
                    {formatHours(usage.hours)}
                  </HoursCell>
                  <AmountCell />
                  <PaymentStatusCell />
                  <InvoiceStatusCell />
                  <CheckboxCell>
                    <Checkbox
                      isDisabled={
                        !!edit?.isExcluded ||
                        !!edit?.excludedPackages?.[p.id] ||
                        !!invoice
                      }
                      isChecked={!edit?.excludedUsages?.[usage.id]}
                      onChangeValue={(checked) =>
                        onPatchEdit(report.id, {
                          excludedUsages: {
                            ...edit?.excludedUsages,
                            [usage.id]: !checked,
                          },
                        })
                      }
                    />
                  </CheckboxCell>
                </TogglUsage>
              ))}
            </PackageOuter>
          ))}

          {report.items?.map(
            ({ id: itemId, description, url, badge, ...item }) => (
              <ItemRow key={itemId}>
                <ItemLabelCell>
                  {description}
                  {!!url && (
                    <a href={url} target={'_blank'}>
                      {' '}
                      <VisitIcon label={''} size={'small'} />
                    </a>
                  )}
                </ItemLabelCell>
                <CsmCell />
                <StartDateCell />
                <ExceptionCell>
                  {badge ? <ItemBadge badge={badge} /> : null}
                </ExceptionCell>
                <HoursCell bold>
                  {'hours' in item && item.hours > 0
                    ? formatHours(item.hours)
                    : ''}
                </HoursCell>
                <AmountCell>
                  <Tooltip
                    content={'formula' in item && <>{item.formula}</>}
                    component={TooltipInner}
                  >
                    <span>
                      {'amount' in item
                        ? formatAmount(item.amount, 'USD')
                        : 'percentage' in item
                        ? `${item.percentage}%`
                        : ''}
                    </span>
                  </Tooltip>
                </AmountCell>
                <PaymentStatusCell />
                <InvoiceStatusCell />
                <CheckboxCell>
                  <Checkbox
                    isDisabled={!!edit?.isExcluded || !!invoice}
                    isChecked={!edit?.excludedItems?.[itemId]}
                    onChangeValue={(checked) =>
                      onPatchEdit(report.id, {
                        excludedItems: {
                          ...edit?.excludedItems,
                          [itemId]: !checked,
                        },
                      })
                    }
                  />
                </CheckboxCell>
              </ItemRow>
            ),
          )}

          {edit?.additionalItems?.map(({ id: itemId, description, amount }) => (
            <ItemRow key={itemId}>
              <ItemLabelCell>{description}</ItemLabelCell>
              <CsmCell />
              <StartDateCell />
              <ExceptionCell />
              <HoursCell />
              <AmountCell>{formatAmount(amount, 'USD')}</AmountCell>
              <PaymentStatusCell />
              <InvoiceStatusCell />
              <CheckboxCell />
            </ItemRow>
          ))}
        </InvoiceContent>

        <MoreMenuCell />
      </InvoiceBody>
    </InvoiceOuter>
  )
}

export default ReportRow
