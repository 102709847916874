import CrossIcon from '@atlaskit/icon/glyph/cross'
import EmailIcon from '@atlaskit/icon/glyph/email'
import { ModalTitle } from '@atlaskit/modal-dialog'
import { useMemo } from 'react'

import { EmailThreadItem } from '../../../../../../graphql'
import useSwitch from '../../../../../../lib/useSwitch'

import EmailMessage from './EmailMessage'
import { CloseButton, MessagesContainer, Modal, ModalHeader } from './styled'

type Props = {
  title?: string
  thread?: EmailThreadItem[]
}

const EmailThread = ({ title = 'Email thread', thread }: Props) => {
  const [isModalOpen, openModal, closeModal] = useSwitch(false)

  const reversedThread = useMemo(() => {
    return thread?.slice().reverse()
  }, [thread])

  if (!reversedThread?.length) {
    return null
  }

  return (
    <>
      <a onClick={openModal}>
        <EmailIcon label={'Email'} />
      </a>
      <Modal
        heading={
          <ModalHeader>
            <ModalTitle>{title}</ModalTitle>
            <CloseButton onClick={closeModal}>
              <CrossIcon label={''} />
            </CloseButton>
          </ModalHeader>
        }
        width={'large'}
        isOpen={isModalOpen}
        onClose={closeModal}
        autoFocus
      >
        <MessagesContainer>
          {reversedThread.map((item, index) => (
            <EmailMessage key={index} message={item} />
          ))}
        </MessagesContainer>
      </Modal>
    </>
  )
}

export default EmailThread
