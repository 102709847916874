import AddIcon from '@atlaskit/icon/glyph/add-circle'
import ManagerIcon from '@atlaskit/icon/glyph/people'
// @ts-ignore
import { ContainerHeader, Group, Item } from '@atlaskit/navigation-next'
import { colors } from '@atlaskit/theme'
import Tooltip from '@atlaskit/tooltip'
import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'
import React, { Fragment, useMemo } from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'

import { LoadingSpinner } from '../../../components/Spinner'
import TeamAvatar from '../../../components/teams/Avatar'
import AssignSuccessManagerModal, {
  EntityType,
} from '../../../components/users/AssignSuccessManagerModal'
import Avatar, { UserAvatarsGroup } from '../../../components/users/Avatar'
import CreateExecutiveWorkspaceModal from '../../../components/users/CreateExecutiveWorkspaceModal'
import {
  isExecutivable,
  isAssistantable,
  UserCategory,
  isTeam,
  BasicTeamWithPrincipalFragment,
  BasicTeamFragment,
  WorkspaceWithTeamFragment,
  useGetUserNavigationQuery,
} from '../../../graphql'
import useSwitch from '../../../lib/useSwitch'
import WorkspaceItem from '../Workspace/WorkspaceItem'

import { NavLink, Outer, PrincipalUser } from './styled'

// TS helper to filter workspace teams (and not think about nulls)
const isWorkspaceTeam = (
  item: BasicTeamWithPrincipalFragment | undefined | null,
): item is BasicTeamWithPrincipalFragment => !!item

const getFriendlyTeamName = ({ name }: BasicTeamFragment) => {
  if (name?.toLocaleLowerCase().includes('team')) {
    return name
  }

  return `${name} Team`
}

const NavigationSidebar = () => {
  const { userId } = useParams<{ userId: string }>()
  const {
    params: { activeWorkspaceId },
  } = useRouteMatch<{ activeWorkspaceId: string }>(
    '/users/:userId/workspaces/:activeWorkspaceId',
  ) || { params: {} }
  const { data, loading } = useGetUserNavigationQuery({
    variables: { id: userId },
    skip: !userId,
  })

  const [
    isCreateWorkspaceModalOpen,
    openCreateWorkspaceModal,
    closeCreateWorkspaceModal,
  ] = useSwitch(false)

  const [
    isShowingSuccessManagerModal,
    showSuccessManagerModal,
    hideSuccessManagerModal,
  ] = useSwitch(false)

  const user = data?.user

  const successManager = isAssistantable(user)
    ? user.dsm
    : isExecutivable(user)
    ? user.csm?.user
    : null

  const [csmSourceTeam, csmSourceTooltip] = useMemo(() => {
    const csmSource = isExecutivable(user) ? user.csm?.source : null
    const csmSourceTeam = isTeam(csmSource) ? csmSource : null
    const csmSourceTooltip = csmSourceTeam
      ? `CSM inherited from ${getFriendlyTeamName(
          csmSourceTeam,
        )} because there is no CSM assigned to the individual.`
      : null

    return [csmSourceTeam, csmSourceTooltip]
  }, [user])

  const workspaces = useMemo(() => {
    const executive: WorkspaceWithTeamFragment[] = []
    const assistant: WorkspaceWithTeamFragment[] = []
    const spectator: WorkspaceWithTeamFragment[] = []

    if (user) {
      user.workspaces.forEach((workspace) => {
        if (
          workspace.executives.some(({ id }: { id: string }) => id === user.id)
        ) {
          executive.push(workspace)
        } else if (
          workspace.assistants.some(({ id }: { id: string }) => id === user.id)
        ) {
          assistant.push(workspace)
        } else {
          spectator.push(workspace)
        }
      })
    }

    return {
      executive,
      assistant,
      spectator,
    }
  }, [user])

  const assistantWorkspaces = useMemo(
    () =>
      sortBy(
        workspaces.assistant,
        // Miranda Priestly at the bottom
        (workspace) =>
          workspace.executives[0]?.id === '5f3ef4ff37cab300b14d56fc'
            ? 'b'
            : 'a',
        // "group" by teams
        (workspace) =>
          workspace.team?.principalUser.id ||
          workspace.executives[0]?.principalUser?.id ||
          workspace.executives[0]?.id,
        // In team, put principal user at the top
        (workspace) =>
          (workspace.team?.principalUser &&
            workspace.team?.principalUser.id !== workspace.executives[0]?.id) ||
          workspace.executives[0]?.principalUser
            ? 'b'
            : 'a',
        // then sort by name
        (workspace) => workspace.executives[0]?.profile.displayName,
      ),
    [workspaces.assistant],
  )

  // don't display teams for assistants
  const userTeams = useMemo(
    () =>
      isExecutivable(user)
        ? // remove duplicates (in the case that a user has multiple workspaces in the same team)
          uniqBy(
            user.workspaces
              // only show teams that the user is not a spectator of
              .filter(
                ({ id }) =>
                  !workspaces.spectator.some(
                    (spectatorWorkspace) => spectatorWorkspace.id === id,
                  ),
              )
              .map(({ team }) => team)
              .filter(isWorkspaceTeam),
            'id',
          )
        : [],
    [user, workspaces.spectator],
  )

  if (!userId) {
    return null
  }

  let previousPrincipalId: string | null = null

  return (
    <Outer>
      <NavLink to={`/users/${userId}`} $striked={user?.isArchived}>
        <ContainerHeader
          text={user?.profile.displayName}
          isSelected={!activeWorkspaceId}
          before={() => (user ? <Avatar user={user} /> : null)}
        />
      </NavLink>
      {isExecutivable(user) && (
        <>
          <Group heading={'Exec Workspaces'}>
            {workspaces.executive
              .filter((workspace) => !workspace.isArchived)
              .map((workspace) => (
                <WorkspaceItem
                  key={workspace.id}
                  workspace={workspace}
                  isSelected={activeWorkspaceId === workspace.id}
                  userId={userId}
                  friendlyTeamName={getFriendlyTeamName(
                    workspace?.team ?? { id: 'unknown' },
                  )}
                />
              ))}
            <Item
              text={'Add workspace'}
              before={() => (
                <AddIcon
                  label={''}
                  primaryColor={colors.N50}
                  secondaryColor={colors.N20}
                />
              )}
              spacing={'compact'}
              onClick={openCreateWorkspaceModal}
            />
          </Group>
          <Group heading={'Archived Workspaces'}>
            {workspaces.executive
              .filter((workspace) => workspace.isArchived)
              .map((workspace) => (
                <WorkspaceItem
                  key={workspace.id}
                  workspace={workspace}
                  isSelected={activeWorkspaceId === workspace.id}
                  userId={userId}
                  friendlyTeamName={getFriendlyTeamName(
                    workspace?.team ?? { id: 'unknown' },
                  )}
                />
              ))}
          </Group>
        </>
      )}
      {!!userTeams?.length ? (
        // Teams v2
        <Group heading={'Teams'}>
          {userTeams.map((team) => (
            <Fragment key={team.id}>
              <NavLink to={`/teams/${team.id}`}>
                <Item
                  text={team?.name}
                  before={() => <TeamAvatar team={team} size={'small'} />}
                  spacing={'compact'}
                />
              </NavLink>
            </Fragment>
          ))}
        </Group>
      ) : (
        // Legacy Teams - to remove once teams have been migrated over
        <>
          {isExecutivable(user) && user.principalUser && (
            <Group heading={'Team'}>
              <NavLink to={`/users/${user.principalUser.id}`}>
                <Item
                  text={user.principalUser.profile.displayName}
                  subText={'Team Principal'}
                  before={() => (
                    <Avatar size={'small'} user={user.principalUser!} />
                  )}
                  spacing={'compact'}
                />
              </NavLink>
            </Group>
          )}
        </>
      )}
      {!!assistantWorkspaces.length && (
        <Group heading={'EA Workspaces'}>
          {assistantWorkspaces.map((workspace) => {
            // check Teams v2 format first, then fallback to legacy Teams
            const principalUser =
              workspace.team?.principalUser &&
              workspace.team?.principalUser.id !== workspace.executives[0]?.id
                ? workspace.team?.principalUser
                : workspace.executives[0]?.principalUser

            return (
              <Fragment key={workspace.id}>
                {/* Display "ghost" principal user if EA does not support principal */}
                {principalUser && previousPrincipalId !== principalUser.id && (
                  <PrincipalUser>
                    <Item
                      text={principalUser.profile.displayName}
                      subText={
                        workspace.team?.name ||
                        principalUser.profile.email?.split('@').pop()
                      }
                      before={() => (
                        <Avatar size={'small'} user={principalUser} />
                      )}
                      spacing={'compact'}
                    />
                  </PrincipalUser>
                )}
                <NavLink
                  key={workspace.id}
                  to={`/users/${userId}/workspaces/${workspace.id}`}
                  $isIndented={!!principalUser}
                >
                  <Item
                    isSelected={activeWorkspaceId === workspace.id}
                    text={
                      workspace.executives[0]?.profile.displayName ||
                      (workspace.isPool ? '🚧 Pool' : '')
                    }
                    subText={
                      workspace.team?.name ||
                      workspace.executives[0]?.profile.email?.split('@').pop()
                    }
                    before={() => (
                      <UserAvatarsGroup
                        size={'small'}
                        maxCount={2}
                        users={[...workspace.executives]}
                        isPool={workspace.isPool}
                      />
                    )}
                    after={() =>
                      workspace.team ? (
                        <Tooltip content={getFriendlyTeamName(workspace.team)}>
                          <TeamAvatar team={workspace.team} size={'xsmall'} />
                        </Tooltip>
                      ) : null
                    }
                    spacing={'compact'}
                  />
                </NavLink>
                {(previousPrincipalId =
                  principalUser?.id || workspace.executives[0]?.id) && null}
              </Fragment>
            )
          })}
        </Group>
      )}
      {user?.category &&
        [UserCategory.ASSISTANT, UserCategory.EXECUTIVE].includes(
          user.category,
        ) && (
          <Group
            heading={
              user.category === UserCategory.ASSISTANT
                ? 'Ops Manager'
                : 'Customer Success Manager'
            }
          >
            <Tooltip content={csmSourceTooltip}>
              <Item
                text={
                  successManager?.profile.displayName ||
                  (user.category === UserCategory.ASSISTANT
                    ? 'Assign Ops Manager'
                    : 'Assign Success Manager')
                }
                onClick={showSuccessManagerModal}
                before={() =>
                  successManager ? (
                    <UserAvatarsGroup
                      size={'small'}
                      maxCount={2}
                      users={[successManager]}
                    />
                  ) : (
                    <ManagerIcon label={''} />
                  )
                }
                after={() =>
                  csmSourceTeam ? (
                    <TeamAvatar team={csmSourceTeam} size={'xsmall'} />
                  ) : null
                }
                spacing={'compact'}
              />
            </Tooltip>
          </Group>
        )}

      {!!workspaces.spectator.length && (
        <Group heading={'Observed Workspaces'}>
          {workspaces.spectator.map((workspace) => (
            <NavLink
              key={workspace.id}
              to={`/users/${userId}/workspaces/${workspace.id}`}
            >
              <Item
                isSelected={activeWorkspaceId === workspace.id}
                text={workspace.executives[0]?.profile.displayName}
                subText={workspace.assistants[0]?.profile.displayName}
                before={() => (
                  <UserAvatarsGroup
                    size={'small'}
                    maxCount={2}
                    users={[...workspace.executives, ...workspace.assistants]}
                  />
                )}
                after={() =>
                  workspace.team ? (
                    <Tooltip content={getFriendlyTeamName(workspace.team)}>
                      <TeamAvatar team={workspace.team} size={'xsmall'} />
                    </Tooltip>
                  ) : null
                }
                spacing={'compact'}
              />
            </NavLink>
          ))}
        </Group>
      )}
      <LoadingSpinner show={loading} />
      <CreateExecutiveWorkspaceModal
        executiveId={userId}
        isOpen={isCreateWorkspaceModalOpen}
        onClose={closeCreateWorkspaceModal}
      />
      {user && (
        <AssignSuccessManagerModal
          isOpen={isShowingSuccessManagerModal}
          onClose={hideSuccessManagerModal}
          entityId={user.id}
          entityType={EntityType.USER}
          userCategory={user.category}
        />
      )}
    </Outer>
  )
}

export default NavigationSidebar
