import styled from 'styled-components'

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const ToggleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
