import { typography } from '@atlaskit/theme'
import { DateTime } from 'luxon'
import React from 'react'
import styled from 'styled-components'

const Outer = styled.time`
  ${typography.h300}
`

type Props = {
  date: string | null | undefined | Date
}

const DisplayDate = ({ date }: Props) => {
  if (!date) {
    return null
  }

  const dateAsDateTime =
    typeof date === 'string'
      ? DateTime.fromISO(date)
      : DateTime.fromJSDate(date)

  const formatedDate = dateAsDateTime.toLocaleString({
    day: 'numeric',
    month: 'short',
  })

  return (
    <Outer dateTime={formatedDate} title={formatedDate}>
      {formatedDate}
    </Outer>
  )
}

export default DisplayDate
