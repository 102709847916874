import EditorFeedbackIcon from '@atlaskit/icon/glyph/editor/feedback'
import { colors } from '@atlaskit/theme'
import Tooltip from '@atlaskit/tooltip'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import UserCell from '../../../../../components/users/Cell'
import { AssistantUser } from '../../../../../graphql'

const Outer = styled.span`
  display: flex;
  align-items: center;
`

type Props = {
  assistant?: AssistantUser | null
  feedback?: string | null
}

const Assistant = ({ assistant, feedback }: Props) => {
  if (!assistant) {
    return null
  }

  return (
    <Outer>
      <Link to={`/users/${assistant.id}`} target={'_blank'}>
        <UserCell user={assistant} />
      </Link>
      {feedback && (
        <Tooltip content={feedback}>
          <EditorFeedbackIcon
            label={'Feedback'}
            size={'small'}
            primaryColor={colors.primary()}
          />
        </Tooltip>
      )}
    </Outer>
  )
}

export default Assistant
