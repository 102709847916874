import Lozenge from '@atlaskit/lozenge'
import { colors } from '@atlaskit/theme'
import { tint, shade, readableColor } from 'polished'
import React, { ComponentProps } from 'react'
import styled from 'styled-components'

import { notFalsy } from '../lib/utils'

const getLightColor = tint(0.88)
const getDarkColor = shade(0.34)

const Outer = styled.div`
  display: inline-block;

  & + & {
    margin-left: 8px;
  }
`

type Colors = {
  backgroundColor: string
  color?: string
}

const TINTS = {
  yellow: {
    backgroundColor: colors.yellow(),
  },
  red: {
    backgroundColor: colors.red(),
    color: colors.N20,
  },
  blue: {
    backgroundColor: colors.blue(),
  },
  green: {
    backgroundColor: colors.green(),
    color: colors.N20,
  },
  purple: {
    backgroundColor: colors.purple(),
  },
  teal: {
    backgroundColor: colors.teal(),
  },
  none: {
    backgroundColor: '#00000000',
  },
}

export type Tint = keyof typeof TINTS

type Props = ComponentProps<typeof Outer> & {
  emoji?: string | null | undefined
  text?: string | number | null | undefined
  tint?: Tint | Colors
  isLight?: boolean | null | undefined
}

const Badge = ({ emoji, text, tint, isLight, ...outerProps }: Props) => {
  let style: Colors | undefined =
    (typeof tint === 'string' ? TINTS[tint] : tint) || undefined

  if (isLight && style) {
    style = {
      backgroundColor: getLightColor(style.backgroundColor),
      color: getDarkColor(style.backgroundColor),
    }
  }

  if (style?.backgroundColor && !style.color) {
    style = {
      ...style,
      color: readableColor(
        style.backgroundColor,
        colors.text(),
        colors.N0,
        false,
      ),
    }
  }

  return (
    <Outer {...outerProps}>
      <Lozenge style={style}>
        {[emoji, text].filter(notFalsy).join(' ')}
      </Lozenge>
    </Outer>
  )
}

export default Badge
